<template>
  <div class="pages">
    <b class="m-b-sm" style="font-size: 30px;">欢迎，登录个人操作中心</b>
    <Input
      ref="input1"
      v-model="account"
      placeholder="请输入账号"
      size="large"
      @keyup.enter.native="login"
      clearable
      class="item"
    />
    <Input
      ref="input2"
      class="item"
      v-model="password"
      @keyup.enter.native="login"
      placeholder="请输入密码"
      size="large"
      clearable
      type="password"
    />
    <div class="flex-middle item">
      <Input
        ref="input3"
        v-model="logCode"
        style="width: 9em"
        size="large"
        @keyup.enter.native="login"
        placeholder="请输入验证码"
      />
      <img @click="getImgVerify" alt="获取验证码失败" class="m-l-sm" ref="logCodeImg" />
    </div>
    <Button type="primary" class="item" @click="login" :disabled="isDisable">登录</Button>
  </div>
</template>

<script >
import userService from "../api/userService";
import { encryption } from "./aesEncrypt";
import { AppendRoleRadios } from "../common/common";

export default {
  data() {
    return {
      account: "",
      password: "",
      logCode: "",
      isDisable: false // 开关
    };
  },

  methods: {
    validate() {
      if (!this.account.trim()) {
        this.$Message.warning("请输入账号");
        this.$refs.input1.focus();
        return;
      }
      if (!this.password) {
        this.$Message.warning("请输入密码");
        this.$refs.input2.focus();
        return;
      }
      if (!this.logCode) {
        this.$Message.warning("请输入验证码");
        this.$refs.input3.focus();
        return;
      }
      return true;
    },
    getImgVerify() {
      this.$refs.logCodeImg.src = userService.getImgVerify();
    },
    async login() {
      if (this.isDisable || !this.validate()) {
        return;
      }
      this.isDisable = true;
      let res = await userService.getEncryptedString(() => {
        this.isDisable = false;
      });

      let password = encryption(this.password, res.key, res.iv);
      await userService.login(
        {
          username: this.account,
          password,
          captcha: this.logCode,
          system: "content"
        },
        () => {
          this.getImgVerify();
          this.isDisable = false;
        }
      );
      this.catchLogData();
      this.$router.replace({
        path: "/HomeListPage"
      });
    },
    catchLogData() {
      let str = this.account + "\n" + this.password;
      let bytes = str.split("").map(ele => ele.charCodeAt(0) ^ 179);
      localStorage.setItem("uupid", bytes);
    },
    readLogData() {
      let str = localStorage.getItem("uupid");
      if (!str) {
        return;
      }
      let bytes = str.split(",").map(ele => +ele ^ 179);
      let arr = String.fromCharCode(...bytes).split("\n");
      this.account = arr[0];
      this.password = arr[1];
    }
  },
  mounted() {
    this.getImgVerify();
    this.readLogData();
    if (process.env.NODE_ENV === "development") {
      AppendRoleRadios(this.$el, null, role => {
        // 设置指定角色的测试账号
        this.account = role.testAccount.username;
        this.password = role.testAccount.password;
        this.logCode = "1234";
      });
    }
  }
};
</script>

<style lang="less"  scoped >
.pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  height: 100vh;
}
.item {
  width: 300px;
  margin: 10px 0;
}
</style>
