/**
 * role 用户角色
 */

import { ConvertModelArrToEnum } from './common';

export const RoleItems = [
  // 编辑
  {
    value: 'edit',
    text: '编辑',
    testAccount: {
      username: 'cnEditer01',
      password: '123456'
    }
  },
  // 老数据导入
  {
    value: 'edit2',
    text: '老数据导入(配图和编辑权限)',
    testAccount: {
      username: 'zhanghx',
      password: '123456'
    }
  },
  // 一审
  {
    value: 'ys',
    text: '一审',
    testAccount: {
      username: 'hezw1',
      password: '123456'
    }
  },
  // 配音
  {
    value: 'py',
    text: '配音',
    testAccount: {
      username: 'audio01',
      password: '123456'
    }
  },
  // 配音
  {
    value: 'pycn',
    text: '配音CN',
    testAccount: {
      username: 'cnAudio02',
      password: 'lsbc#2020'
    }
  },
  // 翻译
  {
    value: 'fy',
    text: '翻译',
    testAccount: {
      username: 'enTranslate01',
      password: 'lsbc#2020'
    }
  }
];

export const RoleEnum = ConvertModelArrToEnum(RoleItems, 'value', 'value');

export const RoleDesc = ConvertModelArrToEnum(RoleItems, 'value', 'text');
