/**
 * common 有业务有关的一些通用函数
 */

import { RoleItems } from './role';

// 将指定的模型集合数据转换为枚举类型
export function ConvertModelArrToEnum(items, valueKey = 'value', textKey = 'text') {
  return items.reduce((prev, cur) => {
    prev[cur[valueKey]] = cur[textKey];
    return prev;
  }, {});
}

// 动态添加角色选择控件，用于 develop 模式
export function AppendRoleRadios(container, targetEl, callback) {
  // 动态添加角色 radio 选择器
  let htmlStr = '';
  // 默认选中第一项
  const cur = 0;
  RoleItems.forEach((role, index) => {
    htmlStr += `<label><input name="role" type="radio" value="${index}" ${index === cur ? 'checked' : ''}/>${
      role.text
    }</label>&nbsp;`;
  });
  htmlStr = `<div class="roles-radios">${htmlStr}</div>`;
  const element = document.createElement('div');
  element.innerHTML = htmlStr;
  container.insertBefore(element.children[0], targetEl || container.firstChild);
  // 绑定 radio change 事件
  container.querySelectorAll('.roles-radios input').forEach($radio => {
    $radio.onclick = e => {
      callback(RoleItems[e.target.value]);
    };
  });
  //callback(RoleData[cur]);
}
